<template>
  <section class='selector-wrap xa-cell'>
    <div class='selector-slider' v-show='needTypeSelect'>
      <el-radio-group v-model='type' class='selector-radio'>
        <el-radio label='商品'></el-radio>
        <el-radio label='商品分类'></el-radio>
      </el-radio-group>
    </div>
    <div class='xa-flex' style='overflow:hidden;height: 530px;'>
      <TransitionGroupSlide :direction="type === '商品' ? 'top' : 'bottom'">
        <ProductTable
          v-show="type == '商品'"
          key='商品'
          :selectType='selectType'
          ref='selectProductVm'
          :params='params'
          @submit='onSubmit'
          @show-product='showCheckedProduct'
          @close="$emit('close')"
        />
        <ProductClassify
          v-show="type == '商品分类'"
          key='商品分类'
          :selectType='selectType'
          :params='params'
          @submit='onSubmit'
          @close="$emit('close')"
        />
      </TransitionGroupSlide>
    </div>
  </section>
</template>
<script>
import TransitionGroupSlide from '@/components/xa-form/TransitionGroupSlide.vue'

export default {
  components: {
    TransitionGroupSlide,
    ProductTable: () => import('./SelectProductTable.vue'),
    ProductClassify: () => import('./SelectProductClassify.vue')
  },
  props: {
    params: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      needTypeSelect: true,
      type: '商品',
      selectType: 'checkbox' // 单选/多选 radio/checkbox
    }
  },
  methods: {
    onSubmit(results) {
      this.$emit('submit', {
        type: this.type,
        results: JSON.parse(JSON.stringify(results))
      })
    },
    // 显示已选商品对话框
    showCheckedProduct(products) {
      this.$emit('show-product', {
        type: this.type,
        products: JSON.parse(JSON.stringify(products))
      })
    },
    // 更新已选数据
    updateTableCheck(list) {
      this.$nextTick(() => {
        if (this.$refs.selectProductVm) {
          this.$refs.selectProductVm.updateTableCheck(list)
        } else {
          var timer = setInterval(() => {
            if (this.$refs.selectProductVm) {
              this.$refs.selectProductVm.updateTableCheck(list)
              clearInterval(timer)
            }
          }, 500)
        }
      })
    }
  },
  mounted() {
    // use_limit_type 券的类型
    const { use_limit_type } = this.params
    if (use_limit_type === 2) {
      // 单品券只需要选择商品
      this.needTypeSelect = false
      this.type = '商品'
    }
  }
}
</script>
<style lang='scss' scoped>
.selector-wrap {
  align-items: stretch;
  height: 100%;

  .selector-slider {
    width: 140px;
    // height: 100%;
    margin-right: 8px;
    border-right: 1px solid #efefef;
  }
}

.selector-radio {
  /deep/ .el-radio {
    line-height: 40px;
  }
}
</style>
